<template>
  <div class="wrap">
    <div class="left">
      <img src="../../assets/img/reportLogo.png" />
      <h1>南亚东南亚建设工程材料及设备价格波动监测</h1>
      <div class="meau">
        <router-link
          to="/ReportData"
          class="link"
          :class="{ active: meauIndex == 1 }"
          tag="button"
          >数据查询</router-link
        >
        <router-link
          to="/ReportSmart"
          class="link"
          :class="{ active: meauIndex == 2 }"
          tag="button"
          >智能报告</router-link
        >
      </div>
    </div>
    <div class="right">
      <router-link to="/" class="link">回到首页</router-link>
      <el-dropdown>
        <span class="el-dropdown-link">设置中心</span>
        <el-dropdown-menu slot="dropdown" style="min-width:96px">
          <el-dropdown-item @click.native="$refs.info.isShow = true"
            >账户信息</el-dropdown-item
          >
          <el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <HeaderUserInfo :userInfo="userInfo" ref="info" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import HeaderUserInfo from "./HeaderUserInfo";
export default {
  name: "ReportHeader",
  components: {
    HeaderUserInfo,
  },
  props: {
    meauIndex: {
      type: [String, Number],
      default: 1,
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    ...mapMutations(["storeClearUserInfo"]),
    async loginOut() {
      await this.$reqApi.loginOut();
      this.$message.success("退出登录成功!");
      this.storeClearUserInfo();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 78px;
  margin: 0 50px 0 30px;
  .left {
    display: flex;
    align-items: center;
    img {
      width: 45px;
      flex-shrink: 0;
      margin-right: 10px;
    }
    h1 {
      color: #2c2d33;
      font-size: 20px;
    }
    .meau {
      display: flex;
      margin-left: 105px;
      text-align: center;
      .link {
        display: block;
        width: 100px;
        font-size: 14px;
        color: #8e9099;
        padding-top: 42px;
        height: 78px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          color: #2c2d33;
          background: #f6f7fe;
        }
        &:first-child {
          background: url("../../assets/img/reportHeaderIcon-1.png") no-repeat
            center 18px;
          &:hover {
            background-position: center -60px;
          }
        }
        &:last-child {
          background: url("../../assets/img/reportHeaderIcon-2.png") no-repeat
            center 21px;
          &:hover {
            background-position: center -58px;
          }
        }
        &.active,
        &.active:hover {
          background-color: #f6f7fe;
          border-bottom: 2px solid #635ef9;
          background-position: center -140px;
          color: #635ef9;
        }
      }
    }
  }
  .right {
    display: flex;
    .link {
      font-size: 14px;
      color: #8d8f99;
      padding-right: 20px;
      &:hover {
        color: #7f94ff;
      }
    }
    .el-dropdown {
      padding-left: 20px;
      cursor: pointer;
      color: #409eff;
      border-left: 2px solid #d2d3dc;
    }
  }
}
</style>
