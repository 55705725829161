<template>
<div class="table_wrap">
  <el-table :data="dataList" style="width: 100%" stripe @selection-change="handleSelectionChange" >   
    <el-table-column type="selection" width="30" fixed></el-table-column> 
    <el-table-column  :label="item" :fixed="index==0" v-for="(item, index) in header" :key='index' :width="index==0?220:(header.length-1)==index?'':120" align="center">
      <template slot-scope="scope">
        <div v-if="index==0" style="color:#6c7dff;display:flex;justify-content:space-between;">
          {{scope.row[index]}} 
          <!-- <i>区县数据</i> -->
        </div>
        <template v-else>
          {{scope.row[index]}}
        </template>
        <template v-if="!isNaN(scope.row[index]) && (currentType==2 || currentType==3)">
          <img v-if="scope.row[index]>0" src="../../../../assets/img/up.png"/>
          <img v-if="scope.row[index]<0" src="../../../../assets/img/down.png"/>
        </template>              
      </template>
    </el-table-column>
  </el-table>
  <CatChart :chartData="chartData" ref="CatChart"/>
</div>  
</template>

<script>
import CatChart from "./CatChart"
export default {
  name: "Table",
  components: {
    CatChart
  },
  props:{
    currentType:[String,Number],  // 指数-同比-环比-价格切换索引
    tableData:Object,   // 数据来源
  },
  data() {
    return{
      header:[],
      dataList:[],
      chartData: {
        legendData:[],
        xAxisData:[],   
        seriesData:[]  //表数据多选
      }
    }
  },
  watch:{
    tableData(newValue){
       this.tableData=newValue
       this.formatData()
    }
  },
  methods:{
    formatData(){     
      this.header=['地区',...Object.values(this.tableData.field_data),'']  //table一行开始最后占位
      let subData=[],tableData=this.tableData.data,legend=[]
      tableData.children.forEach((item,index)=>{
        subData[index]=[item.aname].concat(Object.values(item.data))
        legend.push(item.aname)
      })
      this.dataList=[[tableData.aname,...Object.values(tableData.data)],...subData]
      this.chartData.xAxisData=Object.keys(this.tableData.field_data)
      this.chartData.legendData=[tableData.aname,...legend]
    },
    handleSelectionChange(Multiples) {  // 表单多选     
      this.chartData.seriesData=Multiples
    },
    openChart(){
      if(this.chartData.seriesData.length<1) return this.$message.error("请至少选择一个地区数据")
      this.$refs.CatChart.isChartShow=true
      this.$nextTick(() => {
        this.$refs.CatChart.initChart()
      })
    }
  }
};
</script>

<style scoped lang="less">
 .table_wrap{
    /deep/ .el-table td,/deep/ .el-table th{padding: 8px 0;}
    /deep/ .el-table thead th{background: rgb(176,189,255);color:#fff; border-right: 1px solid #fff;}
    /deep/ .el-table thead th:last-child,/deep/ .el-table thead th:nth-last-child(2){ border-right:0;}
    /deep/ .el-table thead .el-table-column--selection{border-right:0; padding-left: 5px;}
    /deep/ .el-table-column--selection .cell{text-overflow: clip;}      
    /deep/ .el-table .el-table__row td:first-child{color: #6064fd !important;}
    /deep/ .el-table__row:first-of-type , /deep/ .el-table__row:first-of-type td {    background: #8aa3ff!important;color: #fff!important;}
     /deep/ .el-table__row:first-of-type td *{color: #fff!important;}
  }
</style>
