<template>
<div class="table">
  <el-table :data="dataList" stripe style="width: 100%">
    <el-table-column type="expand" fixed>
      <template slot-scope="scope">
        <el-table :data="dataListChild[scope.$index]" stripe style="width: 100%" :show-header='false'>
          <el-table-column width="48"></el-table-column>
          <el-table-column  :label="item" v-for="(item, index) in header" :key='index' :width="index==0?220:(header.length-1)==index?'':120" :align="index==0?'left':'center'">
            <template slot-scope="scope">
              <div v-if="index==0" style="padding-left:20px">
                {{scope.row[index]}}<i v-if="currentType==4" class="unit">（{{unitList[index][scope.$index]}}）</i>
              </div>
              <div v-else>
                {{scope.row[index]}} 
              </div>              
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-table-column>
    <el-table-column :label="item" :fixed="index==0" v-for="(item, index) in header" :key='index' :width="index==0?220:(header.length-1)==index?'':120" :align="index==0?'left':'center'">
      <template slot-scope="scope">
        <template>
          {{scope.row[index]}}
        </template>
        <template v-if="!isNaN(scope.row[index]) && (currentType==2 || currentType==3)">
          <img v-if="scope.row[index]>0" src="../../../../assets/img/up.png"/>
          <img v-if="scope.row[index]<0" src="../../../../assets/img/down.png"/>
        </template>        
      </template>
    </el-table-column>
  </el-table>
</div>  
</template>

<script>
export default {
  name: "Table",
  props:{
    currentType:[String,Number],  // 指数-同比-环比-价格切换索引
    tableData:Object,   // 数据来源
  },
  data() {
    return{
      header:[],
      dataList:[],
      dataListChild:[],
      unitList:[]
    }
  },
  watch:{
    tableData(newValue){
       this.tableData=newValue
       this.formatData()
    }
  },
  methods:{
    formatData(){
      this.header=['材料',...Object.values(this.tableData.field_data),'']  //table第一列和最后一列占位
      let subData=[],tableData=this.tableData.data,subChildData=[],unit=[]
      tableData.forEach((item,index)=>{
        subData.push([item.name,...Object.values(item.areaData)])
        subChildData[index]=[]
        unit[index]=[]
        item.children.forEach((subItem)=>{
          subChildData[index].push([subItem.name,...Object.values(subItem.areaData)])
          unit[index].push(subItem.unit_name)
        })
      })
      this.dataList=subData
      this.dataListChild=subChildData
      this.unitList=unit
    }
  }
};
</script>

<style scoped lang="less">
 .table{
    /deep/ .el-table td,/deep/ .el-table th{padding: 8px 0;}
    /deep/ .el-table thead th{background: rgb(176,189,255);color:#fff; border-right: 1px solid #fff;}
    /deep/ .el-table thead th:last-child,/deep/ .el-table thead th:nth-last-child(2){ border-right:0;}
    /deep/ .el-table thead .el-table__expand-column{border-right:0; padding-left: 5px;color: rgb(176,189,255) !important;}
    /deep/ .el-table-column--selection .cell{text-overflow: clip;}      
    /deep/ .el-table .el-table__row td:nth-child(2), /deep/ .el-table__expand-icon{color: #6064fd !important;}
    /deep/ .el-table__expand-icon>.el-icon{font-size: 16px !important; font-weight: bold !important;}
    /deep/.el-table__expanded-cell {padding: 0 !important}
    .unit{font-size: 12px;}
  }
</style>
