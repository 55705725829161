<template>
    <el-container class="report_wrap">
        <el-header>
            <ReportHeader meauIndex="1" />
        </el-header>

        <el-container>
            <el-aside>
                <SideNav
                    @changeSideTitleIndex="changeSideTitleIndex"
                    @changeCountry="changeCountry"
                    @changeSideItem="changeSideItem"
                    :baseData="treeData"
                    :nat="nationid"
                />
            </el-aside>
            <el-container>
                <el-main>
                    <Content @changeCountry="changeCountry" :sideObj="sideObj" :nat="nationid" />
                </el-main>
                <el-footer>
                    <CopyRightFooter />
                </el-footer>
            </el-container>
        </el-container>
    </el-container>
</template>

<script>
import ReportHeader from "../components/Report/ReportHeader";
import CopyRightFooter from "../components/Report/CopyRightFooter";
import SideNav from "../components/Report/ReportData/SideNav";
import Content from "../components/Report/ReportData/Content";
export default {
    name: "report",
    components: {
        ReportHeader,
        CopyRightFooter,
        SideNav,
        Content,
    },
    data() {
        return {
            nationid: 2, // 默认国家
            sideObj: {
                sideTitleIndex: 0,
                sideCatItem: {}, // 默认侧边栏按材料查询第一项
                sideAreaItem: {}, // 默认侧边栏按地区查询第一项
            },
            treeData: {},
        };
    },
    created() {
        // 加载状态管理中的默认国家
        let nationid = sessionStorage.getItem("nationid");
        if (nationid) {
            this.nationid = Number(nationid);
        }

        this.getBySide();
    },
    methods: {
        async getBySide() {
            // 获取侧边栏数据
            this.treeData = await this.$reqApi.getDataBySide();
            this.sideObj.sideCatItem = this.treeData.categorises[0];
            if (this.nationid == 3) {
                this.sideObj.sideAreaItem = this.treeData.areas[1];
            } else {
                this.sideObj.sideAreaItem = this.treeData.areas[0];
            }
            this.changeCountry(this.nationid);
            this.$store.commit(
                "storeSaveSideCatItem",
                this.treeData.categorises[0]
            ); //侧边栏点击项保存到Store
            this.$store.commit("storeSaveOptions", this.treeData.option);
        },
        changeSideTitleIndex(index) {
            // 保存点击侧边栏title索引
            this.$set(this.sideObj, "sideTitleIndex", index);
        },
        // 监听修改国家方法
        changeCountry(e) {
            console.log("监听到变动", e);
            this.nationid = e;
            sessionStorage.setItem("nationid", e);
        },
        changeSideItem(item) {
            // 保存点击侧边栏树形菜单点击项
            if (this.sideObj.sideTitleIndex == 0) {
                this.$set(this.sideObj, "sideCatItem", item);
                this.$store.commit("storeSaveSideCatItem", item); //侧边栏点击项保存到Store
            }
            if (this.sideObj.sideTitleIndex == 1) {
                console.log("点击树形菜单", item);
                this.$set(this.sideObj, "sideAreaItem", item);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.report_wrap {
    min-height: 100%;
    min-width: 1440px;
    .el-header {
        box-shadow: 0 8px 14px 0 rgba(33, 58, 233, 0.1);
        height: auto !important;
        padding: 0;
        margin-bottom: 8px;
    }
    .el-container {
        background-color: #f6f7fe;
        .el-aside {
            min-height: 100%;
            box-shadow: 0 8px 14px 0 rgba(33, 58, 233, 0.05);
            background: #fff;
            width: 270px !important;
            margin-top: -8px;
        }
        .el-footer {
            height: auto !important;
        }
    }
}
</style>
