<template>
  <div class="copy_right">
    <p>
      指导单位：云南省住房和城乡建设厅科技与标准定额处<i>|</i>主办单位：云南省工程建设技术经济室<i
        >|</i
      >
      数据及技术支持：昆明行列科技有限公司<i>|</i>联系电话：0871-68184040
    </p>
    <p>
      备案号：滇ICP备18001273号 Copyright 2018-2019 版权所有
      昆明行列科技有限公司
    </p>
  </div>
</template>

<script>
export default {
  name: 'CopyRightFooter',
}
</script>

<style scoped lang="less">
.copy_right {
  font-size: 12px;
  color: #fff;
  text-align: center;
  color: #666;
  height: auto !important;
  background-color: #f6f7fe;
  p {
    &:last-child {
      margin: 10px 0 20px;
      color: #999;
    }
    i {
      padding: 0 14px;
    }
  }
}
</style>
