<template>
  <div class="chart_wrap">
    <el-dialog width="900px" :visible.sync="isChartShow">
      <h1><i>东南亚{{$store.state.sideCatItem.name}}指数对比柱状图</i></h1>
      <ul class="switchChart">
        <li @click="switchChart('line')" :class="{'active':chartType=='line'}">线图</li>
        <li @click="switchChart('bar')" :class="{'active':chartType=='bar'}">柱状图</li>
        <li @click="switchChart('mixin')" :class="{'active':chartType=='mixin'}">混合图</li>
      </ul>
      <div ref="myChart" :style="{'width':width,'height':height,'margin':'0 auto'}"></div>
    </el-dialog>
  </div>    
</template>

<script>
export default {
  name:'Chart',
  props:{
    width:{
      type:String,
      default:'100%'
    },
    height:{
      type:String,
      default:'400px'
    },
    chartData:Object,   // 数据来源
  },
  data() {
    return {
      isChartShow:false,
      chartType:'bar',
      color:['#7bc9ff','#eea8c3','#7be2db','#fedcc1','#ade3f6','#b6c6fe','#efbbb2','#fa9583','#b4fce3','#e4c3f4','#77a1ff','#f1dc4f','#bc8878','#f9b7e1','#c890c3','#9eca98','#ff7571'],
      myChart:''
    }   
  },
  watch:{
    chartData(newValue){
      this.chartData=newValue
    }
  },
  methods:{
    switchChart(type){
      this.chartType=type
      this.initChart(type);
    },
    formatData(type){
      let lineSeriesData=[],barSeriesData=[],mixinSeriesData=[]
      this.chartData.seriesData.forEach((item,index)=>{
        let [name,...data]=item
        lineSeriesData.push({name,type:'line',itemStyle:{color:this.color[index]},data})
        barSeriesData.push({name,type:'bar',itemStyle:{color:this.color[index]},data})
        mixinSeriesData.push({name,type:'bar',itemStyle:{color:this.color[index]},data},{name,type:'line',itemStyle:{color:this.color[index]},data})
      })
      if(type=='line') return lineSeriesData
      if(type=='bar') return barSeriesData
      if(type=='mixin') return mixinSeriesData
    },
    initChart(type=this.chartType) {  //画图表
      this.myChart = this.$echarts.init(this.$refs.myChart)  // 基于准备好的dom，初始化echarts实例
      this.myChart.clear()
      // 绘制条形图
      var options={
          //title: {text: '云南省电气装备用电线电缆指数对比柱状图',top: 5,left: 'center'},
          legend: {data:this.chartData.legendData , bottom:0,type:'scroll'},
          tooltip : {trigger: 'axis',axisPointer: {type: 'cross'}},
          xAxis: { //X轴
            name:'时间',
            nameTextStyle:{fontSize:14,color:'#6064FD'},
            data: this.chartData.xAxisData
          },
          yAxis: { // Y轴
            name:'指数',
            nameTextStyle:{fontSize:14,color:'#6064FD'}
          }, 
          // 数据
          series:this.formatData(type)
      }
      this.myChart.setOption(options) 
    }
  }
};
</script>

<style lang="less" scoped>
  .chart_wrap{
    /deep/ .el-dialog__body{padding-top:3px ;}
    h1{text-align: center;
      i{display: inline-block; border-radius: 40px;color: #fff;background: #637cfb;font-size: 20px; padding: 8px 20px;}
    }
    .switchChart{display: flex; align-items: center; margin: 20px 0 0 60px;
      li{margin-right:10px; width: 50px; height: 50px;background: url('../../../../assets/img/chartIcon.png') no-repeat 0 0; border-radius: 50%; text-indent: -999em; overflow: hidden; cursor: pointer;
        &:nth-child(2){ background-position: -60px 0;}
        &:nth-child(3){ background-position: -120px 0;}
        &:hover{ background-position-y: -61px;}
        &.active{ background-position-y: -122px !important;}
      }
    }
  }  
</style>
