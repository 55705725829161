<template>
  <!-- <div class="content" v-loading.fullscreen="loading"> -->
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>数据查询</el-breadcrumb-item>
        <el-breadcrumb-item>{{ sideObj.sideTitleIndex==0?'各地材料数据':'各地材料对比' }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="filter_wrap">
      <h1>东南亚各地区材料数据</h1>
      <ul>
        <li v-for="(item,index) in timeFilterArray" :key='index' @click="handleTimeFilter(item)" :class="{'active':currentTimeFilter.id==item.id}">{{item.name}}</li>
      </ul>

      <el-select v-if="this.sideObj.sideTitleIndex==0" size="small" class="sele" v-model="nationid" placeholder="请选择">
        <el-option label="缅甸" :value="2">
        </el-option>
        <el-option label="老挝" :value="3">
        </el-option>
      </el-select>

      <el-button class="btn" type="primary" round @click="showChart" v-if="this.sideObj.sideTitleIndex==0">显示勾选地区对比图表</el-button>
    </div>
    
    <div class="data_wrap">
      <div class="data_header">
        <div class="left">
          <h1 v-if="sideObj.sideTitleIndex==0">各地区<i>"{{sideObj.sideCatItem.name}}"</i>{{currentTimeFilter.name}}表</h1>
          <h1 v-else><i>"{{sideObj.sideAreaItem.name}}"</i>{{currentTimeFilter.name}}表</h1>
          <ul class="data_filter">
            <li v-for="(item,index) in typeFilterArray" :key="item" @click="handleDataFilter(index)" :class="{'active':currentTypeFilter==index}">{{item}}</li>
          </ul>
          <div class="price_filter" v-if="typeFilterArray[currentTypeFilter]=='价格'">
            <ul>
              <li @click="handleMoneyFilter(1)" :class="{'active':currentMoneyIndex==1}">人民币</li>
              <li @click="handleMoneyFilter(2)" :class="{'active':currentMoneyIndex==2}">美元</li>
              <li @click="handleMoneyFilter(3)" :class="{'active':currentMoneyIndex==3}">当地货币</li>
            </ul>
            <span v-show="sideObj.sideTitleIndex==0">{{unit}}</span>
          </div>
          <el-popover class="tips" title="指数定义" width="200" trigger="hover" v-if="currentTypeFilter==1"> 
            <i slot="reference">指数说明<img src="../../../assets/img/wh.png"></i>
            <div class="tips_content">
              <p>反映了市场材料价格变动情况的相对数。定基指数=（报告期价格/基期价格）* 基期价格指数</p>
              <p>基准期：2020年3月</p>
              <p>基期价格指数：1000</p>
            </div>
          </el-popover>
        </div>
        <div class="right">
          时间
          <el-select v-model="selectTime" placeholder="请选择查询时间" @change="getData">
            <el-option v-for="(item,index) in selectTimeOptions" :key="index" :label="item" :value="index"></el-option>
          </el-select>
        </div>        
      </div>
      <div class="data_body">
        <CatTable :tableData="tableData" :currentType="currentTypeFilter" ref="CatTable" v-if="this.sideObj.sideTitleIndex==0"/>  
        <AreaTable :tableData="tableData" :currentType="currentTypeFilter" v-if="this.sideObj.sideTitleIndex==1"/>  
      </div>
    </div>
  </div>
</template>

<script>
import AreaTable from "./AreaTable/AreaTable"
import CatTable from "./CatTable/CatTable"
export default {
  name: "Content",
  components: {
    AreaTable,CatTable
  },
  props:{
    // 外部传递过来的国家
    nat: {
      default:2,
      type: Number
    },
    sideObj:Object,   // 侧边栏状态
  },
  data() {
    return{
      loading:false,
      nationid: 2, // 国家的ID
      timeFilterArray:[  
        {id: 1, name: "年度数据"},
        {id: 2, name: "季度数据"},
        {id: 3, name: "月度数据"}
      ],
      currentTimeFilter:{id: 3, name: "月度数据"},
      typeFilterArray:{},  // 指数-同比-环比-价格切换
      currentTypeFilter:1,  // 默认指数显示
      currentMoneyIndex:1,  // 默认人民币显示
      unit:'', // 价格单位
      selectTimeOptions:{},  // 时间下拉项
      selectTime:'',  // 时间下拉选中项
      isshwoTips:true,  // 是否显示指数说明
      tableData:{},  // 表格渲染数据
      chartData:[]   // 图表渲染数据
    }
  },
  watch:{
    nat(e) {
      console.log('组件监听到变动1', e)
      this.nationid = e
    },
    nationid(e) {
      this.$emit("changeCountry",e);  
      
      this.getData()
    },
    '$store.state.selectTimeOptions'(){
      this.initOptions()
    },
    sideObj:{
       handler(newValue) {
        this.sideObj.sideTitleIndex = newValue.sideTitleIndex;
        this.sideObj.sideCatItem = newValue.sideCatItem;
        this.sideObj.sideAreaItem = newValue.sideAreaItem;
        this.currentTypeFilter=this.currentMoneyIndex=1
        this.getData()
      },
      deep: true
    }
  },
  created(){   
    this.nationid  = this.nat
    this.getData()
  },
  methods:{
    initOptions(){  // 初始化年度、季度、月度数据等下拉项
      if(this.currentTimeFilter.id==1){
        this.selectTimeOptions=this.$store.state.selectTimeOptions.WPF_DATE_TYPE_YEARS
        this.selectTime=Object.keys(this.selectTimeOptions)[0] 
      }
      else if(this.currentTimeFilter.id==2){
        this.selectTimeOptions=this.$store.state.selectTimeOptions.WPF_DATE_TYPE_QUARTER
        this.selectTime=Object.keys(this.selectTimeOptions)[0] 
      }
      else{
        this.selectTimeOptions=this.$store.state.selectTimeOptions.WPF_DATE_TYPE_MONTH
        this.selectTime=Object.keys(this.selectTimeOptions)[1] 
      }
    },
    async getData(){
      let res,reqObj={
        nationid: this.nationid, // 国家的ID
        terms_type:this.currentTimeFilter.id,
        date_num:this.selectTime,
        data_type:this.currentTypeFilter,
        roe:this.currentMoneyIndex
      }
      if(this.sideObj.sideTitleIndex==0){
        reqObj.categorys=this.sideObj.sideCatItem.id
        res = await this.$reqApi.getDataByCat(reqObj)      
      }else{
        console.log('查询地区',this.sideObj.sideAreaItem.id, this.sideObj.sideAreaItem)
        reqObj.areas=this.sideObj.sideAreaItem.id
        res = await this.$reqApi.getDataByArea(reqObj)
      }
      this.typeFilterArray=res.field_header.data 
      this.unit=res.field_header.unit_name
      this.tableData=res //表格数据
      this.loading=false
    },
    handleTimeFilter(item){ // 年度、季度、月度数据切换
      this.currentTimeFilter = item
      this.initOptions()
      this.getData()
    },
    handleDataFilter(index){  // 指数、同比、环比、价格切换
      this.currentTypeFilter = index
      this.getData()
    },
    handleMoneyFilter(index){
      this.currentMoneyIndex=index
      this.getData()
    },
    showChart(){ // 显示图表
      this.$refs.CatTable.openChart()
    }
  }
};
</script>

<style scoped lang="less">
  .breadcrumb{background: #fff;cursor: pointer;display:inline-flex; align-items: center; justify-content: center;padding:0 30px;border-radius: 8px; min-width: 158px;box-shadow: 0 8px 14px 0 rgba(33,58,233,.05); height: 38px; margin: 10px 0;
    &:before{ content: '';display: inline-block;width: 6px;height: 6px;background-color: #7f94ff;border-radius: 8px;margin-right: 8px;}
    .el-breadcrumb{
      .el-breadcrumb__item{
        &:last-child{
          /deep/ .el-breadcrumb__inner{cursor: pointer !important;color:#333; font-weight: bold;}
        }
      }
    }
  }
  .filter_wrap{background: #fff; box-shadow: 0 8px 14px 0 rgba(33,58,233,.05);border-radius: 8px;padding: 18px 30px;display:flex; align-items: center; margin:10px 0;
    h1{font-size: 20px;color: #6064fd;}
    ul{display:flex; align-items: center; justify-content: center;margin: 0 0 0 20px;
      li{color: #8e9099;border: 1px solid #d6d9e2;text-align: center;cursor: pointer;font-size: 12px; padding:8px 16px; margin-left:-1px;
        &.active{background: #6064fd;border: 1px solid #7f94ff;color: #fff;}
      }
    }
    .sele {
      width: 100px;
      margin-left: 20px;
    }
    .btn {
      margin-left: 20px;
    }
    .el-button.is-round{background: #7f94ff;padding: 8px 20px;border-color: #7f94ff;}
    .el-button.is-round:hover{background: #6064fd;border-color: #6064fd;}
  }
  .data_wrap{background: #fff;border-radius: 8px; overflow: hidden; margin:20px 0; padding: 20px;
    .data_header{ font-size: 20px; height: 56px; padding: 0 40px; background: #6064fd; border-radius: 4px 4px 0 0; display: flex; align-items: center; justify-content: space-between; color: #fff;
      .left{display: flex; align-items: center;
        h1 i{ color: #7bfaff; font-weight: normal; padding: 0 6px;}
        .data_filter{display: flex; font-size: 14px; color: #bdbef8; cursor: pointer; line-height: 1; align-items: center; margin-left: 60px;
          li{ margin-left: 20px;
            &.active{ font-size: 18px; color: #fff;}
          }
        }
        .price_filter{ display:flex; align-items: center; font-size: 12px;
          ul{display:flex; margin: 0 6px 0 26px;
            li{color: #fff; border: 1px solid #d6d9e2; text-align: center; cursor: pointer; font-size: 12px; padding:4px 16px; margin-left:-1px;
              &.active{ color: #6064fd; background: #fff; }
            }
          }
        }
        .tips{ color: #fefefe; font-size: 12px; margin-left: 40px; cursor: pointer;
          img{margin-left: 4px;}
        }
      }
      .right{ font-size: 14px; flex-shrink: 0;
        .el-select{width: 130px;margin-left:6px;}
        /deep/ .el-input__inner,/deep/ .el-input__icon{height: 34px; line-height: 34px;}
      }
    }    
  }
</style>
