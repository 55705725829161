<template>
  <div class="userinfo_wrap">
    <el-dialog title="账户信息" width="480px" :visible.sync="isShow">
      <el-form :model="userInfo" label-width="60px">
        <el-form-item label="账号">
          <el-input v-model="userInfo.username" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="userInfo.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="单位">
          <el-input v-model="userInfo.company" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input v-model="userInfo.mobile" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="userInfo.email" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="地区">
          <el-input v-model="rangeArea" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="isShow=false">确定</el-button>
      </div>
    </el-dialog>
  </div>    
</template>

<script>
export default {
  props:{
    userInfo:Object
  },
  data() {
    return {
      labelWidth: "60px",
      isShow:false
    }   
  },
  computed:{
    rangeArea(){
      return this.userInfo.areas && this.userInfo.areas.join('＞')
    }
  }
};
</script>

<style lang="less" scoped>
  .userinfo_wrap{
    /deep/ .el-dialog__header{line-height:24px;font-size:18px;color:#303133}
    /deep/ .el-dialog__body{padding-bottom:0!important}
    /deep/ .el-form{padding-right:20px;}
    /deep/ .el-dialog__footer{display:flex;justify-content:flex-end;padding-top: 0;}
  }  
</style>
