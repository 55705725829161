<template>
    <div class="side_wrap">
        <h1 :class="{'active':showArr[0]}" @click="handleTitle(0)">
            按材料查询
            <i class="iconfont">&#xe620;</i>
        </h1>
        <el-tree
            v-show="showArr[0]"
            :data="treeList1"
            :props="defaultProps"
            :default-expanded-keys="defaultExpand"
            :accordion="true"
            node-key="id"
            @node-click="handleClick1"
        ></el-tree>
        <h1 :class="{'active':showArr[1]}" @click="handleTitle(1)">
            按地区查询
            <i class="iconfont">&#xe620;</i>
        </h1>
        <el-tree
            v-show="showArr[1]"
            :data="treeList2"
            :props="defaultProps"
            :default-expanded-keys="defaultExpand2"
            :accordion="true"
            node-key="id"
            @node-click="handleClick2"
        ></el-tree>
    </div>
</template>

<script>
export default {
    name: "SideNav",
    props: {
        baseData: Object,
        nat: {
            default: 2,
            type: Number,
        },
    },
    watch: {
        baseData(newval) {
            this.treeList1 = newval.categorises;
            this.treeList2 = newval.areas;
            this.defaultExpand = [this.treeList1[0].id];
            this.defaultExpand2 = [this.nationid];
        },
        nat(e) {           
            this.nationid = e;
        },
        nationid(e){
            this.defaultExpand2=[e];
        }
    },
  created(){   
    this.nationid  = this.nat    
  },
    data() {
        return {
            showArr: [true].concat(Array(1).fill(false)), // 控制菜单折叠,默认展开第一项
            showIndex: 0, // 记录哪个展开
            nationid: 2, // 记录哪个展开
            defaultProps: {
                children: "children",
                label: "name",
            },
            defaultExpand: [1, 51],
            defaultExpand2: [2],
            treeList1: [], // 材料数据
            treeList2: [], // 地区数据
        };
    },
    methods: {
        handleTitle(index) {
            if (this.showIndex == index) {
                return;
            }
            this.$set(
                this.showArr,
                this.showIndex,
                !this.showArr[this.showIndex]
            ); // 收起展开的树形菜单
            this.$set(this.showArr, index, !this.showArr[index]); // 展开当前点击的树形菜单
            this.showIndex = index; // 保留当前点击的树形菜单索引
            this.$emit("changeSideTitleIndex", index); // 通知父组件改变索引状态
        },
        handleClick1(item) {
            // 材料查询树形菜单点击
            this.$emit("changeSideTitleIndex", 0);
            this.$emit("changeSideItem", item);
        },
        handleClick2(item) {
            // 地区查询树形菜单点击
            // 国家切换
            this.nationid = item.nationid;
            this.$emit("changeCountry", item.nationid);
            this.$emit("changeSideTitleIndex", 1);
            this.$emit("changeSideItem", item);
        },
    },
};
</script>

<style scoped lang="less">
.side_wrap {
    h1 {
        height: 58px;
        padding: 0 20px 0 13px;
        font-size: 14px;
        box-sizing: border-box;
        cursor: pointer;
        line-height: 58px;
        margin-bottom: 10px;
        &:before {
            content: "";
            display: inline-block;
            width: 30px;
            height: 30px;
            vertical-align: middle;
        }
        &:first-of-type:before {
            background: url("../../../assets/img/sideNavIcon-1.png") no-repeat 0
                4px;
        }
        &:last-of-type:before {
            background: url("../../../assets/img/sideNavIcon-2.png") no-repeat 0
                2px;
        }
        i {
            font-size: 12px;
            float: right;
        }
        &.active {
            background: linear-gradient(270deg, #61e0ff, #6439f8) !important;
            color: #fff;
            &:before {
                background-position: 0 -76px;
            }
            i {
                transform: rotate(90deg);
            }
        }
    }
    /deep/ .el-tree-node.is-current > .el-tree-node__content {
        background: #7f94ff !important;
        color: #fff;
    }
    /deep/ .is-current > .el-tree-node__content * {
        color: #fff !important;
    }
    /deep/ .el-icon-caret-right {
        font-family: iconfont !important;
    }
    /deep/ .el-tree-node__content .el-icon-caret-right:before {
        content: "\e620";
    }
    /deep/ .el-tree-node__children .el-icon-caret-right:before {
        content: "";
    }
    /deep/ .el-tree-node__expand-icon {
        color: #637cfb;
    }
    /deep/ .el-tree-node__label {
        color: #2c2d33;
    }
    /deep/ .el-tree-node__content {
        padding: 0 13px !important;
        height: 30px;
    }
}
</style>
